import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  pairwise,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs';
import { EventService, getId, User, UserFilter } from '@common';
import { AdminUserHttpService } from '@http';

@Injectable({ providedIn: 'platform' })
export class EhsAddWaiverUsersSearchDialogService {
  users$: Observable<User[]> = of([]);
  hasNext$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  search$: BehaviorSubject<{
    firstName: string;
    lastName: string;
    pageNumber: number;
  }> = new BehaviorSubject({ firstName: '', lastName: '', pageNumber: 1 });

  constructor(private adminUserHttp: AdminUserHttpService) {}

  public initUsers(eventService: EventService): void {
    this.users$ = this.search$.pipe(
      switchMap(({ firstName, lastName, pageNumber }) =>
        this.adminUserHttp.listUsers({
          pageNumber,
          company: getId(eventService.company),
          firstName,
          lastName
        })
      ),
      // Used so pairwise works correctly
      startWith({ users: [], hasNext: false }),
      pairwise(),
      map(([{ users: lastUsers }, { users, hasNext }]) => {
        this.hasNext$.next(hasNext);

        return [...lastUsers, ...users];
      }),
      shareReplay(1)
    );
  }

  public searchUsers(filter: UserFilter): void {
    this.search$.next({
      firstName: filter.firstName,
      lastName: filter.lastName,
      pageNumber: 1
    });
  }

  public loadMoreUsers(): void {
    const current = this.search$.getValue();

    this.search$.next({
      ...current,
      pageNumber: current.pageNumber + 1
    });
  }

  public getUsers$(): Observable<User[]> {
    return this.users$;
  }
}
