import { DbDocument } from '../db-document';
import { User } from '../user';
import { ObjectId } from 'mongodb';
import { isDbDocument } from '../../util/is-db-document';

/**
 * Collection of fitness data collected from mobile app.
 * Should only have 1 per user incentive.
 */
export interface UserHealthData extends DbDocument {
  /**
   * The user the data was synced from.
   */
  user: User | string | ObjectId;

  /**
   * The health value fetched from the user's fitness tracker.
   * Because multiple records per day can be returned on the app,
   * this will be an aggregation of all values of the same type
   * for the same day if numeric
   */
  value: string | number;

  /**
   * Date which the data was created. This record will be aggregating
   * all records for this date.
   */
  recordDate: string;

  /**
   * The measurement unit for this type of health data.
   */
  unit: HealthDataUnit;
  /**
   * The type of health data retrieved i.e. steps, hours of sleep, etc.
   */
  type: HealthDataType;
  /**
   * Workout category
   */
  workoutCategory?: WorkoutCategory;

  /**
   * Device it was fetched from. Should only be iOS or Android
   */
  platform: PlatformTypes;
}

export enum HealthDataType {
  STEPS = 'steps',
  SLEEP_ASLEEP = 'sleep_asleep',
  WORKOUT = 'workout',
  UNKNOWN = 'unknown'
}

export enum HealthDataUnit {
  COUNT = 'count',
  MINUTE = 'minute',
  UNKNOWN = 'unknown'
}

export enum PlatformTypes {
  IOS = 'ios',
  ANDROID = 'android',
  FITBIT = 'fitbit',
  UNKNOWN = 'unknown'
}

/**
 * Type-guard for user-requisitions, will actually logically accept any db-document, but
 * extra type-guards will help
 */
export const isUserHealthData = (
  userHealthData: string | ObjectId | UserHealthData
): userHealthData is UserHealthData =>
  isDbDocument<UserHealthData>(userHealthData);

/**
 * Subcategories of workout activity which users can submit. Different OS present
 * different lists of options to users.
 * We're just storing duration of their activity. Otherwise, different activities
 * store different values such as calories burned or distance and requires additional,
 * higher permissions to fetch on mobile.
 */
export enum WorkoutCategory {
  // AVAILABLE ON ANDROID AND IOS
  ARCHERY = 'archery',
  BADMINTON = 'badminton',
  BASEBALL = 'baseball',
  BASKETBALL = 'basketball',
  BIKING = 'biking',
  BOXING = 'boxing',
  CRICKET = 'cricket',
  CURLING = 'curling',
  ELLIPTICAL = 'elliptical',
  FENCING = 'fencing',
  AMERICAN_FOOTBALL = 'american_football',
  AUSTRALIAN_FOOTBALL = 'australian_football',
  SOCCER = 'soccer',
  GOLF = 'golf',
  GYMNASTICS = 'gymnastic',
  HANDBALL = 'handball',
  HIGH_INTENSITY_INTERVAL_TRAINING = 'high_intensity_interval_training',
  HIKING = 'hiking',
  HOCKEY = 'hockey',
  SKATING = 'skating',
  JUMP_ROPE = 'jump_rope',
  KICKBOXING = 'kickboxing',
  PILATES = 'pilates',
  RACQUETBALL = 'racquetball',
  RUGBY = 'rugby',
  RUNNING = 'running',
  ROWING = 'rowing',
  SAILING = 'sailing',
  CROSS_COUNTRY_SKIING = 'cross_country_skiing',
  DOWNHILL_SKIING = 'downhill_skiing',
  SNOWBOARDING = 'snowboarding',
  SOFTBALL = 'softball',
  SQUASH = 'squash',
  STAIR_CLIMBING = 'stair_climbing',
  SWIMMING = 'swimming',
  TABLE_TENNIS = 'table_tennis',
  TENNIS = 'tennis',
  VOLLEYBALL = 'volleyball',
  WALKING = 'walking',
  WATER_POLO = 'water_polo',
  YOGA = 'yoga',
  OTHER = 'other',
  // AVAILABLE ON IOS ONLY
  BOWLING = 'bowling',
  CROSS_TRAINING = 'cross_training',
  TRACK_AND_FIELD = 'track_and_field',
  DISC_SPORTS = 'disc_sports',
  LACROSSE = 'lacrosse',
  PREPARATION_AND_RECOVERY = 'preparation_and_recovery',
  FLEXIBILITY = 'flexibility',
  WHEELCHAIR_WALK_RACE = 'wheelchair_walk_race',
  WHEELCHAIR_RUN_PACE = 'wheelchair_run_pace',
  HAND_CYCLING = 'hand_cycling',
  CORE_TRAINING = 'core_training',
  FUNCTIONAL_STRENGTH_TRAINING = 'functional_strength_training',
  TRADITIONAL_STRENGTH_TRAINING = 'traditional_strength_training',
  MIXED_CARDIO = 'mixed_cardio',
  STAIRS = 'stairs',
  STEP_TRAINING = 'step_training',
  FITNESS_GAMING = 'fitness_gaming',
  BARRE = 'barre',
  CARDIO_DANCE = 'cardio_dance',
  SOCIAL_DANCE = 'social_dance',
  MIND_AND_BODY = 'mind_and_body',
  PICKLEBALL = 'pickleball',
  CLIMBING = 'climbing',
  EQUESTRIAN_SPORTS = 'equestrian_sports',
  FISHING = 'fishing',
  HUNTING = 'hunting',
  PLAY = 'play',
  SNOW_SPORTS = 'snow_sports',
  PADDLE_SPORTS = 'paddle_sports',
  SURFING_SPORTS = 'surfing_sports',
  WATER_FITNESS = 'water_fitness',
  WATER_SPORTS = 'water_sports',
  TAI_CHI = 'tai_chi',
  WRESTLING = 'wrestling',
  // AVAILABLE ON ANDROID ONLY THROUGH GOOGLE FIT OR HEALTH CONNECT
  AEROBICS = 'aerobics',
  BIATHLON = 'biathloc',
  CALISTHENICS = 'calisthenics',
  CIRCUIT_TRAINING = 'circuit_training',
  CROSS_FIT = 'cross_fit',
  DANCING = 'dancing',
  DIVING = 'diving',
  ELEVATOR = 'elevator',
  ERGOMETER = 'ergometer',
  ESCALATOR = 'escalator',
  FRISBEE_DISC = 'frisbee_disc',
  GARDENING = 'gardening',
  GUIDED_BREATHING = 'guided_breathing',
  HORSEBACK_RIDING = 'horseback_riding',
  HOUSEWORK = 'houswork',
  INTERVAL_TRAINING = 'interval_training',
  IN_VEHICLE = 'in_vehicle',
  KAYAKING = 'kayaking',
  KETTLEBELL_TRAINING = 'kettlebell_training',
  KICK_SCOOTER = 'kick_scooter',
  KITE_SURFING = 'kite_surfing',
  MEDITATION = 'meditation',
  MIXED_MARTIAL_ARTS = 'mixed_martial_arts',
  P90X = 'p90x',
  PARAGLIDING = 'paragliding',
  POLO = 'polo',
  ROCK_CLIMBING = 'rock_climbing',
  RUNNING_JOGGING = 'running_jogging',
  RUNNING_SAND = 'running_sand',
  RUNNING_TREADMILL = 'running_treadmill',
  SCUBA_DIVING = 'scuba_diving',
  SKATING_CROSS = 'skating_cross',
  SKATING_INDOOR = 'skating_indoor',
  SKATING_INLINE = 'skating_inline',
  SKIING_BACK_COUNTRY = 'skiing_back_country',
  SKIING_KITE = 'skiing_kite',
  SKIING_ROLLER = 'skiing_roller',
  SLEDDING = 'sledding',
  STAIR_CLIMBING_MACHINE = 'stair_climbing_machine',
  STANDUP_PADDLEBOARDING = 'standup_paddleboarding',
  STILL = 'still',
  SURFING = 'surfing',
  SWIMMING_OPEN_WATER = 'swimming_open_water',
  SWIMMING_POOL = 'swimming_pool',
  TEAM_SPORTS = 'team_sports',
  TILTING = 'tilting',
  TREADMILL = 'treadmill',
  VOLLEYBALL_BEACH = 'volleyball_beach',
  VOLLEYBALL_INDOOR = 'volleyball_indoor',
  WAKEBOARDING = 'wakeboarding',
  WALKING_FITNESS = 'walking_fitness',
  WALKING_NORDIC = 'walking_nordic',
  WALKING_STROLLER = 'walking_stroller',
  WALKING_TREADMILL = 'walking_treadmill',
  WEIGHTLIFTING = 'weightlifting',
  WHEELCHAIR = 'wheelchair',
  WINDSURFING = 'windsurfing',
  ZUMBA = 'zumba',
  STRENGTH_TRAINING = 'strength_training'
}
