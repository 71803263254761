import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { UserHealthDataHttpService } from '../../http/user-health-data-http.service';
import { userHealthDataActions } from './user-health-data.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserHealthDataEffects {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private userHealthDataHttp: UserHealthDataHttpService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userHealthDataActions.get),
      mergeMap((action) =>
        this.userHealthDataHttp.get(action.id).pipe(
          map((entity) => userHealthDataActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            userHealthDataActions.getFailed(),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userHealthDataActions.list),
      mergeMap((action) =>
        this.userHealthDataHttp.list().pipe(
          map((entities) => userHealthDataActions.listSuccess({ entities })),
          catchError((err: HttpErrorResponse) => [
            userHealthDataActions.listFailed(),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  listByHealthProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userHealthDataActions.listByHealthProgram),
      mergeMap((action) =>
        this.userHealthDataHttp
          .listByHealthProgram({ healthProgramId: action.healthProgramId })
          .pipe(
            map((entities) =>
              userHealthDataActions.listByHealthProgramSuccess({
                entities
              })
            ),
            catchError((err: HttpErrorResponse) => [
              userHealthDataActions.listByHealthProgramFailed(),
              this.errorReportFacade.create({ action, err })
            ])
          )
      )
    )
  );
}
