import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserHealthData } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserHealthDataHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Load a specific user health data records
   */
  public get(id: string): Observable<UserHealthData> {
    return this.http.get<UserHealthData>(`api/v1/health-data/${id}`);
  }

  /**
   * Load all of current users health data
   */
  public list(): Observable<UserHealthData[]> {
    return this.http.get<UserHealthData[]>(`api/v1/health-data`);
  }

  public listByHealthProgram(params: {
    healthProgramId: string;
  }): Observable<UserHealthData[]> {
    return this.http.get<UserHealthData[]>(
      `api/v1/health-data/program/${params.healthProgramId}`
    );
  }
}
