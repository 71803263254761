import { UserHealthData } from '@common';
import { createAction, props } from '@ngrx/store';

export const userHealthDataActions = {
  get: createAction('[USER_HEALTH_DATA] GET', props<{ id: string }>()),
  getSuccess: createAction(
    '[USER_HEALTH_DATA] GET_SUCCESS',
    props<{ entity: UserHealthData }>()
  ),
  getFailed: createAction('[USER_HEALTH_DATA] GET_FAILED'),

  list: createAction('[USER_HEALTH_DATA] LIST'),
  listSuccess: createAction(
    '[USER_HEALTH_DATA] LIST_SUCCESS',
    props<{ entities: UserHealthData[] }>()
  ),
  listFailed: createAction('[USER_HEALTH_DATA] LIST_FAILED'),

  listByHealthProgram: createAction(
    '[USER_HEALTH_DATA] LIST_BY_HEALTH_PROGRAM',
    props<{ healthProgramId: string }>()
  ),
  listByHealthProgramSuccess: createAction(
    '[USER_HEALTH_DATA] LIST_BY_HEALTH_PROGRAM_SUCCESS',
    props<{ entities: UserHealthData[] }>()
  ),
  listByHealthProgramFailed: createAction(
    '[USER_HEALTH_DATA] LIST_BY_HEALTH_PROGRAM_FAILED'
  )
};
